import React from 'react';
import "./home.css"
import { useEffect } from 'react';
import { getPhotos } from '../../store/photo';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from 'react';

const Home = () => {
    const dispatch = useDispatch()
    const photos = useSelector((state)=>state.photos.allPhotos)
    const [val, setVal] = useState("")
    // useEffect(()=>{
    //     dispatch(getPhotos("hello"))
    // },[dispatch])
    const handleSearch = (e) => {
        e.preventDefault()
        dispatch(getPhotos(val))
        setVal("")
    }
    return (
        <div className='home'>
        <h1>Image Search</h1>
        <form className="searchBar" onSubmit={handleSearch}>
            <input type="text"
             placeholder='search'
             value={val}
             onChange={(e)=>setVal(e.target.value)}
             className="search searchOne"
             ></input>

            <button className=" searchbtn"><FontAwesomeIcon icon="fa-solid fa-magnifying-glass" /></button>
        </form>
  
      {photos &&<div>
        {photos.map((url, id)=>(
            <div className="photoDiv">

           
            <img className="photo" key={id}  src={url.url} alt="#"/>
       
            </div>
        ))}

      </div>}

        
        </div>
    )
}


export default Home