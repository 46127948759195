import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

export const photoSlice = createSlice({
    name:'photos',
    initialState: {
        allPhotos:null,
    },
    reducers: {

    },
    extraReducers:(builder) => {
        builder
        .addCase(getPhotos.fulfilled, (state, action)=> {
            state.allPhotos = action.payload
        })
        .addCase(getPhotos.rejected, (state, action)=> {})

    }
});
export const getPhotos = createAsyncThunk(
    'phtoos/getPhotos',
    async (word, {rejectWithValue}) => {
        const response = await fetch(`/photos/${word}`,{
            headers: {
                'Content-Type': 'application/json',
            },
            credentials: 'include',
        });

        if (!response.ok) {
            rejectWithValue(await response.json());
        }

        const data = await response.json();

        return data;

        })

export default photoSlice.reducer