import { configureStore } from '@reduxjs/toolkit';
import photoReducer from './photo';



const store = configureStore({
  reducer: {
    photos:photoReducer,

  }
})

export default store;